<template>
  <Layout>
    <div class="app-home">
      <a-result
        :status="userInfo.status"
        :title="userInfo.msg"
        :sub-title="userInfo.username"
      >
        <template #extra>
          <form :action="userInfo.adspy" method="POST">
            <input type="hidden" name="t" :value="token" />
            <a-button
              v-if="userInfo.service && userInfo.status === 'success'"
              htmlType="submit"
              key="query"
              type="primary"
            >
              前往查询
            </a-button>
          </form>
        </template>

        <div class="desc">
          <p v-if="userInfo.notice" style="font-size: 16px;">
            <strong>{{ userInfo.notice }}</strong>
          </p>
          <p>
            <a-icon :style="{ color: 'red' }" type="info-circle" /> 总点数：{{
              userInfo.total
            }}， 剩余：{{ userInfo.total - userInfo.used }}
          </p>
          <p>
            <a-icon :style="{ color: 'red' }" type="clock-circle" />
            截止日期：{{ userInfo.end_at | formatDate }}
          </p>
        </div>
      </a-result>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

const UserSvc = require("@/service/user");
import { getToken } from "@/service/auth";

export default {
  data() {
    return {
      token: "",

      userInfo: {
        username: "",
        total: "-",
        used: "-",
        start_at: "-",
        end_at: "-",

        status: "info",
        msg: "",
        service: true,
        notice: "",

        adspy: ""
      }
    };
  },

  methods: {
    async loadData() {
      try {
        const info = await UserSvc.info();
        if (!info) {
          this.$message.error("系统在开小差，请稍等");
          return;
        }

        this.userInfo = info;
      } catch (err) {
        // 错误处理
        if (err.message == "Error: Network Error") {
          this.isNetworkError = true;

          this.$message.error("网络故障，请稍等重试");
        } else {
          this.$router.replace({ name: "Logout" });
        }
      }
    }
  },

  async mounted() {
    this.token = getToken();

    this.loadData();
  },

  components: { Layout }
};
</script>

<style></style>
